<template>
  <div>
    <header id="header">
      <h1><img src="/asset/img/common/headerlogo.svg" alt="PLUG キャラクター売上予測AI"></h1>
      <ul class="subnavi">
        <li><a class="noaction">PLUG管理者メニュー</a></li>
        <li>
          <a class="cursorPointer" @click="logout">
            <i class="fa fa-lock pr5" />ログアウト
          </a>
        </li>
      </ul>
    </header>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { actions as actionsUser } from '../store/modules/user'

export default {
  name: 'LayoutAdmin',
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    logout() {
      this.$store.dispatch(actionsUser.LOGOUT)
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss">
.cursorPointer {
  cursor: pointer;
}
</style>
